import React, { useState, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import { Plus, Minus } from 'react-feather';

import styles from '../styles/stepper.module.scss';

const Stepper = ({ value, onChange, disabled = false, min = 0, max = 100 }) => {
    const [val, setVal] = useState(value);

    useEffect(() => {
        setVal(value);
    }, [value]);

    const onMinus = () => {
        const newval = val - 1;
        setVal(newval);
        onChange(newval);
    };

    const onPlus = () => {
        const newval = val + 1;
        setVal(newval);
        onChange(newval);
    };

    return (
        <div className={styles.stepper}>
            <IconButton
                size='small'
                color='primary'
                onClick={onMinus}
				disabled={disabled || !(val > min)}
				title='Remove 1 Quantity'
				aria-label='Remove 1 Quantity'
            >
                <Minus />
            </IconButton>
            <div className={styles.val}>
                {val}
            </div>
            <IconButton
                size='small'
                color='primary'
                onClick={onPlus}
				disabled={disabled || val >= max}
				title='Add 1 Quantity'
				aria-label='Add 1 Quantity'
            >
                <Plus />
            </IconButton>
        </div>
    )
};

export default Stepper;