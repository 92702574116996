import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';
import { Link } from 'gatsby';
import { ShoppingCart } from 'react-feather';
import _ from 'underscore';

import StoreContext from '../context/store-context';
import SEO from '../components/seo';
import ImgLoader from '../components/img-loader';
import ProductPrice from '../components/product-price';
import Stepper from '../components/stepper';
import { formatPrice } from '../utils/format-number';
import { getHandle } from '../utils/product-utils';
import { modifyUrlQuery } from '../utils/uri-utils';

import * as AccountActions from '../actions/account-actions';

import styles from '../styles/cart.module.scss';

class CartPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            prefilling: false,
            updating: false,
            items: []
        };
    }

    async componentDidMount() {
        await this.checkPrefill();
        await this.checkItems();
    }

    async componentDidUpdate() {
        await this.checkPrefill();
        await this.checkItems();
    }

    async checkPrefill() {
        const context = this.context;
        const { checkout } = context;

        if (!(checkout && checkout.id)) {
            return;
        }

        if (!(this.props.auth.initialized && this.props.auth.authenticated)) {
            return;
        }

        const { email } = this.props.auth.claims;
        const { user } = this.props.auth;
        const { active_subscriber, discount_percent } = user;
        const { discountApplications } = checkout;

        if (
            checkout.email === email &&
            (
                (!active_subscriber && !discountApplications.length) ||
                (active_subscriber && discountApplications.length && discountApplications[0].code === `MEMBER${discount_percent}`)
            )
        ) {
            return;
        }

        if (this.state.prefilling === true) {
            return;
        }

        await this.setState({ prefilling: true });

        if (!this.props.account.initialized) {
            await this.props.getAccountDetails();
        }

        const {
            fname,
            lname,
            shipping
        } = this.props.account;

        let formatted_shipping = null;

        if (shipping) {
            const {
                addressln1,
                addressln2,
                city,
                state,
                postal
            } = shipping;

            formatted_shipping = {
                address1: addressln1,
                address2: addressln2,
                city,
                country: 'US',
                firstName: fname,
                lastName: lname,
                province: state,
                zip: postal
            };
        }

        await context.addPrefillInfo(
            context.client,
            checkout,
            email,
            formatted_shipping,
            active_subscriber,
            discount_percent
        );

        await this.setState({ prefilling: false });
    }

    async checkItems() {
        const { lineItems } = this.context.checkout;
        const { items } = this.state;

        if (lineItems && lineItems !== items) {
            await this.setState({
                loading: false,
                items: lineItems
            });
        }
    }

    render() {
        const { loading, prefilling, updating, items } = this.state;
        const empty = !(!loading && items && items.length);
        const ready = !empty && !updating && !prefilling;

        const context = this.context;
        const { checkout } = context;

        return (
            <>
                <SEO
                    title='My Cart'
                    path='/cart/'
                    description='Ready to checkout? Our checkout process is fast and easy.'
                />
                <section className={styles.main}>
                    <div className={styles.content}>
                        <div className={styles.cart}>
                            {!empty &&
                                <h5>{`Nice choice${items.length === 1 ? '' : 's'}!`}</h5>
                            }
                            <hr />
                            <ul>
                                {this.renderItems()}
                            </ul>
                        </div>
                        <aside className={styles.meta}>
                            <div className={styles.box}>
                                <h5>Ready to checkout?</h5>
                                <ul>
                                    {this.renderDisclaimer()}
                                    {this.renderSubtotal()}
                                </ul>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    component='a'
                                    href={checkout.webUrl}
                                    disabled={!ready}
                                    className={styles.checkout}
                                    fullWidth={true}
                                >
                                    <ShoppingCart size={18} className={styles.icon} />
                                    GO TO CHECKOUT
                                </Button>
                            </div>
                        </aside>
                    </div>
                </section>
            </>
        );
    }

    renderItems() {
        const { loading, prefilling, updating, items } = this.state;

        if (loading || prefilling) {
            return (
                <li className={styles.loading}>
                    <CircularProgress color='primary' />
                </li>
            );
        }

        const context = this.context;
        const { checkout } = context;
        const empty = !(items && items.length);

        if (empty) {
            return (
                <li className={styles.empty}>
                    <h5>Your cart is empty.</h5>
                    <Button
                        variant='contained'
                        color='primary'
                        component={Link}
                        to='/store/'
                        className={styles.btn}
                    >
                        Add some stuff!
                    </Button>
                </li>
            );
        }

        return items.map((item) => {
            const removeItem = async () => {
                item.removing = true;
                this.setState({ updating: true });
                await context.removeLineItem(context.client, checkout.id, item.id);
                this.setState({ updating: false });
            };

            const updateItem = async (quantity) => {
                item.temp = quantity;
                this.setState({ updating: true });
                await context.updateLineItem(context.client, checkout.id, item.id, quantity);
                this.setState({ updating: false });
            };

            const updateQuantity = _.debounce(updateItem, 500);

            const displayQuantity = typeof (item.temp) === 'number' ? item.temp : item.quantity;

            const handle = getHandle(item);

            const imgurl = modifyUrlQuery(
                item.variant.image.src,
                {
                    width: 128
                }
            );

            return (
                <li key={item.id}>
                    <Link
                        to={`/product/${handle}/`}
                        className={styles.imglink}
                    >
                        <ImgLoader
                            className={styles.imgload}
                            loadingClass={styles.loading}
                            src={imgurl}
                            alt={item.title}
                        />
                    </Link>
                    <div className={styles.center}>
                        <div className={styles.details}>
                            <Link
                                to={`/product/${handle}/`}
                                className={styles.title}
                            >
                                {`${item.title}${item.variant.title !== 'Default Title' ? ' - ' + item.variant.title : ''}`}
                            </Link>
                        </div>
                        <div className={styles.quantity}>
                            <Stepper
                                value={displayQuantity}
                                onChange={updateQuantity}
                                updating={updating}
                            />
                        </div>
                    </div>
                    <div className={styles.right}>
                        <strong className={styles.price}>
                            <ProductPrice
                                value={item.variant.price}
                                showDecimal={true}
                            />
                        </strong>
                        <Button
                            variant='text'
                            size='small'
                            className={styles.remove}
                            disabled={Boolean(item.removing)}
                            onClick={removeItem}
                        >
                            Remove
                        </Button>
                    </div>
                </li >
            );
        });
    }

    renderDisclaimer() {
        if (!this.props.auth.initialized) {
            return null;
        }

        if (!this.props.auth.authenticated) {
            return (
                <>
                    <li className={styles.disclaimer}>{'Do you have a membership?'}</li>
                    <li className={styles.disclaimer}>
                        <Link
                            to='/login/'
                            state={
                                {
                                    redirect: '/cart/'
                                }
                            }
                        >
                            {'Sign in'}
                        </Link>
                        {' first to apply your '}
                        <strong>{'membership discount'}</strong>
                        {'!'}
                    </li>
                </>
            );
        }

        return (
            <li className={styles.disclaimer}>
                Shipping and taxes are calculated at checkout
            </li>
        );
    }

    renderSubtotal() {
        const { updating } = this.state;

        if (updating) {
            return (
                <li>
                    <h6>SUBTOTAL</h6>
                    <span>
                        <CircularProgress
                            color='inherit'
                            size={16}
                        />
                    </span>
                </li>
            );
        }

        const context = this.context;
        const { checkout } = context;

        return (
            <li>
                <h6>SUBTOTAL</h6>
                <span>
                    {formatPrice(checkout.subtotalPrice || 0)}
                </span>
            </li>
        );
    }
}

CartPage.contextType = StoreContext;

const mapState = (state) => ({
    auth: state.auth,
    account: state.account
});

const mapDispatch = { getAccountDetails: AccountActions.getAccountDetails };

export default connect(mapState, mapDispatch)(CartPage);
